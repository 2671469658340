import './styles.scss'

import React, { useContext, useEffect, useMemo, useState } from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { HeaderSizeContext } from 'src/Layout'
import { parseItemListElement } from 'src/utils/product/parseItemListElement'
import { useProductsQuery } from 'src/sdk/product/useProductsQuery'
import { Skeleton } from '@acctglobal/skeleton'
import BuyTogether from 'src/components/product/BuyTogether'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import AboutCollection from './AboutCollection'
import ProductShelfPDP from '../ProductShelfPDP/ProductShelfPDP'
import ProductSpecifications from './ProductSpecifications'
import type { IOProduct } from '../PlpSellerStore/types'

interface Props {
  product: BrowserProductQueryQuery['product']
  buyTogetherProducts: IOProduct[]
}

export interface AnchorOffset {
  top: number
}

const ADJUST_OFFSET = 40

const AnchorMenu = ({ product, buyTogetherProducts }: Props) => {
  const {
    id,
    breadcrumbList: { itemListElement },
    isVariantOf: { additionalProperty },
  } = product

  const isPDPLife = itemListElement.some(
    (breadCrumbItem) => breadCrumbItem.name === 'Life'
  )

  const [isLoading, setLoading] = useState(true)
  const [anchorOffset, setAnchorOffset] = useState<AnchorOffset>()
  const { isWindowBiggerThanTablet } = useWindowDimensions()

  const headerSizeContext = useContext(HeaderSizeContext)

  useEffect(() => {
    setLoading(false)
    headerSizeContext?.headerSize &&
      setAnchorOffset({ top: -(headerSizeContext?.headerSize + ADJUST_OFFSET) })
  }, [headerSizeContext])

  const filterSpecification = (query: string) => {
    return additionalProperty.filter(
      (el: { name: string }) =>
        el.name.toLowerCase().indexOf(query.toLowerCase()) > -1
    )
  }

  // This function transforms the itemListElement into the selectedFacets used to filter related products by category
  const selectedFacets = parseItemListElement(itemListElement)

  const checkColecao =
    filterSpecification('Coleção').length > 0
      ? filterSpecification('Coleção')[0]
          .value.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/ /g, '-')
          .toLowerCase()
      : ''

  let completeLook: string | undefined
  let relatedProducts: string | undefined

  const completeLookFacets = () => {
    return completeLook
      ? [{ key: `productclusterids`, value: `${completeLook}` }]
      : [{ key: `colecao`, value: `${checkColecao}` }]
  }

  const hasRelatedProducts = () => {
    return relatedProducts
      ? [{ key: `productclusterids`, value: `${relatedProducts}` }]
      : selectedFacets
  }

  const productList = useProductsQuery({
    first: 6,
    after: '0',
    sort: 'release_desc',
    term: '',
    selectedFacets: completeLookFacets(),
  })

  const products = useMemo(() => {
    const fullProductList = productList?.edges?.map((edge) => edge.node)
    const filterProductList = () => {
      return fullProductList?.filter((item) => {
        return item.id !== id
      })
    }

    fullProductList && filterProductList()

    return fullProductList
  }, [id, productList?.edges])

  const notFullCollection = checkColecao !== '' || completeLook
  const buyTogetherProductsValidate = buyTogetherProducts.filter(
    (buyTogetherProduct) => buyTogetherProduct?.productId !== product?.productId
  )

  return isLoading ? (
    <Skeleton backgroundColor="#F4F4F4" height={400} animation />
  ) : (
    <div>
      <section className="anchor-below-container">
        {isWindowBiggerThanTablet && buyTogetherProductsValidate.length > 0 ? (
          <BuyTogether
            product={product}
            buyTogetherProducts={buyTogetherProductsValidate}
          />
        ) : (
          notFullCollection &&
          products &&
          products.length !== 0 && (
            <>
              <div className="anchor-sub-title finish-look-section">
                <span
                  id="complete-o-look"
                  className="fake-anchor"
                  style={anchorOffset}
                />
                <h2>COMPLETE O LOOK</h2>
              </div>
              <div className="shelf-container">
                <ProductShelfPDP
                  first={6}
                  after="0"
                  selectedFacets={completeLookFacets()}
                  mainProductId={id}
                />
              </div>
            </>
          )
        )}
        <div className="anchor-sub-title anchor-sub-title-details">
          <span
            id="product-details"
            className="fake-anchor"
            style={anchorOffset}
          />
          <ProductSpecifications product={product} isPDPLife={isPDPLife} />
          <AboutCollection anchorOffset={anchorOffset} isPDPLife={isPDPLife} />
        </div>
        <div className="anchor-sub-title">
          <span
            id="relationships-products"
            className="fake-anchor"
            style={anchorOffset}
          />
          PRODUTOS RELACIONADOS
        </div>
        <div className="shelf-container">
          <ProductShelfPDP
            first={6}
            after="0"
            selectedFacets={hasRelatedProducts()}
            mainProductId={id}
          />
        </div>
      </section>
    </div>
  )
}

export default AnchorMenu
