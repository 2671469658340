import type { IStoreSelectedFacet } from '@faststore/api'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Slider from 'react-slick'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import sliderSettings from 'src/configs/slider-pdp-shelf'
import { useProductsQuery } from 'src/sdk/product/useProductsQuery'
import { List as UIList } from '@faststore/ui'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import ProductCard from '../../product/ProductCard'

import './product-shelf.scss'

interface ProductShelfProps {
  first: number
  after: string
  selectedFacets?: IStoreSelectedFacet[]
  mainProductId?: string
}

function ProductShelfPDP({
  first,
  after,
  selectedFacets,
  mainProductId,
}: ProductShelfProps) {
  const sliderRef = useRef<Slider>(null)

  const productList = useProductsQuery({
    first,
    after,
    sort: 'release_desc',
    term: '',
    selectedFacets: selectedFacets ?? [],
  })

  const { width } = useWindowDimensions()

  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    setIsMobile(width <= 1024)
  }, [width])

  const products = useMemo(() => {
    const fullProductList = productList?.edges?.map((edge) => edge.node)

    if (fullProductList !== undefined) {
      return fullProductList.filter((item) => {
        return item.id !== mainProductId
      })
    }

    return fullProductList
  }, [mainProductId, productList?.edges])

  if (products) {
    if ((products.length <= 3 && !isMobile) || products.length === 1) {
      return (
        <div className={`card-collection not-slider-${products.length}`}>
          <ProductShelfSkeleton
            loading={products.length === 0 && mounted === false}
          >
            {products?.length > 0 && (
              <UIList className="card-collection--content">
                {products?.map((product, idx) => (
                  <li className="card-collection--list" key={idx}>
                    <ProductCard
                      product={product}
                      index={idx}
                      key={idx}
                      variant="pdp"
                    />
                  </li>
                ))}
              </UIList>
            )}
          </ProductShelfSkeleton>
        </div>
      )
    }

    return (
      <>
        <ProductShelfSkeleton
          loading={products.length === 0 && mounted === false}
        >
          {products?.length > 0 && (
            <Slider ref={sliderRef} {...sliderSettings}>
              {products.map((product, idx) => (
                <ProductCard
                  product={product}
                  index={idx}
                  key={idx}
                  variant="pdp"
                />
              ))}
            </Slider>
          )}
        </ProductShelfSkeleton>
      </>
    )
  }

  return <></>
}

export default ProductShelfPDP
