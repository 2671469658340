import { useSession } from 'src/sdk/session'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import type { FC } from 'react'
import type { PDPProps } from 'src/pages/[slug]/p'
import { ProductSchema } from 'src/components/common/ProductSchema/ProductSchema'

import storeConfig from '../../../../store.config'

interface Props extends PDPProps {
  product: BrowserProductQueryQuery['product']
}

const Seo: FC<Props> = (props) => {
  const { locale, currency } = useSession()
  const {
    serverData,
    data: { site },
    params: { slug },
    location: { pathname },
  } = props

  if (serverData === null) {
    return null
  }

  const { product } = serverData

  if (!product) {
    throw new Error('NotFound')
  }

  const title = product.seo.title ?? site?.siteMetadata?.title ?? ''
  const description =
    product.seo.description ?? site?.siteMetadata?.description ?? ''

  const { storeUrl } = storeConfig
  const canonical = `${storeUrl}${pathname}`

  return (
    <>
      <GatsbySeo
        title={title}
        description={description}
        canonical={canonical}
        language={locale}
        openGraph={{
          type: 'og:product',
          url: `${site?.siteMetadata?.siteUrl}${pathname}${slug ?? ''}`,
          title,
          description,
          images: product.image?.map((img) => ({
            url: img.url,
            alt: img.alternateName,
          })),
        }}
        metaTags={[
          {
            property: 'product:price:amount',
            content: product?.offers?.lowPrice?.toString() ?? undefined,
          },
          {
            property: 'product:price:currency',
            content: currency.code,
          },
        ]}
      />
      <BreadcrumbJsonLd
        itemListElements={product?.breadcrumbList?.itemListElement ?? []}
      />
      <ProductSchema product={product} />
    </>
  )
}

export default Seo
