import React from 'react'
import ReleaseTag from 'src/components/common/ReleaseTag'
import type { ITagsData } from 'src/components/product/ProductCard/tagsCollection'
import { findCollectionById } from 'src/components/product/ProductCard/tagsCollection'
import dataTagsCollection from 'src/components/product/ProductCard/data/dataTagsCollection.json'

interface Props {
  collections: CollectionsType
  collectionId?: string
  isNewRelease?: boolean
  isVivaraYou?: boolean
}

export default function ProductTags({
  collections,
  collectionId,
  isNewRelease,
  isVivaraYou,
}: Props) {
  const collectionInfo = findCollectionById(collections, collectionId ?? '')
  const tagsData = dataTagsCollection?.tagsData as ITagsData
  const { className, collectionBlock } =
    tagsData?.[collectionId ?? 'default'] ?? tagsData?.default

  return (
    <>
      {!isVivaraYou ? (
        <>
          <div className="tags-container__product-card">
            {collections?.length && collectionId && !collectionBlock && (
              <ReleaseTag
                variant={`pdp-${className}`}
                name={collectionInfo?.name ?? ''}
              />
            )}
          </div>
          {isNewRelease && (!collectionId || collectionBlock) && (
            <ReleaseTag variant="pdp" name="LANÇAMENTO" />
          )}
        </>
      ) : (
        <a href="/colecao/vivara-you/">
          <ReleaseTag
            variant="pdp-vivara-you"
            name={collectionInfo?.name ?? ''}
          />
        </a>
      )}
    </>
  )
}
