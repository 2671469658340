import type { CurrencyCode, AddToCartEvent } from '@faststore/sdk'
import { sendAnalyticsEvent } from '@faststore/sdk'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { cartStore } from 'src/sdk/cart'
import type { CartItem } from 'src/sdk/cart'

import type { SelectedProductsAssemble } from '../Assembly/types'

interface SendToCartProps {
  code: string
  totalPrice: number
  products: CartItem[]
  cartId: string
  itemsCart: CartItem[]
}

export const handleAddToCart = async ({
  products,
  code,
  totalPrice,
  cartId,
  itemsCart,
}: SendToCartProps): Promise<void> => {
  const items = products.map((product) => {
    const productID =
      product.itemOffered.isVariantOf?.additionalProperty?.filter(
        (propertie: { name: string }) => propertie.name === 'Cód'
      )

    const valueProductID: string = productID?.[0]?.value
      ? productID?.[0]?.value
      : product.itemOffered.gtin

    return {
      item_id: product.itemOffered.sku,
      item_name: product.itemOffered.isVariantOf.name,
      item_brand: product.itemOffered.brand.name,
      item_variant: product.itemOffered.sku,
      quantity: product.quantity,
      price: product?.price * product.quantity,
      discount: product?.listPrice - product?.price,
      currency: code as CurrencyCode,
      item_variant_name: product.itemOffered.name,
      product_reference_id: valueProductID,
      dimension1: product.itemOffered.gtin,
    }
  })

  sendAnalyticsEvent<AddToCartEvent>({
    name: 'add_to_cart',
    params: {
      currency: code as CurrencyCode,
      value: totalPrice,
      items,
    },
  })

  cartStore.set({ id: cartId, items: [...itemsCart, ...products] })
}

export const prepareProductsToSend = (
  bracelet: CartItem,
  selectedProductsAssembleBracelet: Array<SelectedProductsAssemble | null>,
  securityChain?: BrowserProductQueryQuery['product']
): CartItem[] => {
  const listProduct: CartItem[] = []

  listProduct.push(bracelet)

  if (securityChain) {
    const securityChainToSend = createCartItem(securityChain)

    listProduct.push(securityChainToSend)
  }

  selectedProductsAssembleBracelet?.map((item) =>
    listProduct.push(createCartItem(item?.product))
  )

  return listProduct.reduce((accumulator: CartItem[], currentItem) => {
    const indexItemFound = accumulator.findIndex(
      (item) => currentItem.id === item.id
    )

    if (indexItemFound !== -1) {
      accumulator[indexItemFound].quantity += 1
    } else {
      accumulator.push(currentItem)
    }

    return accumulator
  }, [])
}

export function createCartItem(
  product: BrowserProductQueryQuery['product'] & { quantity?: number }
): CartItem {
  const productSpotPrice = product?.offers?.lowPrice
  const productOffers = product?.offers?.offers

  const mainIndex =
    productOffers?.findIndex((offer) => offer?.price === productSpotPrice) ===
    -1
      ? 0
      : productOffers?.findIndex((offer) => offer?.price === productSpotPrice)

  const { listPrice, price, seller } = productOffers[mainIndex]

  return {
    ...product,
    seller,
    price,
    listPrice,
    quantity: product.quantity ?? 1,
    itemOffered: { ...product },
  }
}
